import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { getPercentage } from '../../utility/helpers/helpers';
import { Lookup } from '../../utility/helpers/lookup';

@Component({
  selector: 'scale-control',
  templateUrl: './scale-control.component.html',
  styleUrls: ['./scale-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScaleControlComponent implements OnChanges {
  @Input() scale = 1;
  @Input() disable = false;
  @Input() justButtons = false;
  @Output() step = new EventEmitter<number>();
  minScale: number;
  maxScale: number;
  percentage: number;

  constructor(private lookup: Lookup) {
    this.minScale = this.lookup.GraphicsMinScale;
    this.maxScale = this.lookup.GraphicsMaxScale;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.justButtons) {
      if (this.justButtons) {
        this.minScale = -1;
        this.maxScale = -1;
      } else {
        this.minScale = this.lookup.GraphicsMinScale;
        this.maxScale = this.lookup.GraphicsMaxScale;
      }
    }

    if (isNaN(this.scale)) {
      this.scale = 1;
    }

    this.percentage = getPercentage(this.scale, this.minScale);
  }

  handleStep(direction: number) {
    if (this.disable) return;
    const step = this.lookup.GraphicsScaleStep * direction;
    this.step.emit(step);
  }
}
